// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about {
  display: flex;
  background: black;
  color: white;
  height: 200px;
  align-items: center;
}
.about .aboutText {
  width: 25%;
  height: 100px;
  text-align: center;
  border-right: 1px solid white;
}
.about .aboutText:last-child {
  border-right: none;
}
.about .aboutText h1 {
  color: var(--color);
  font-weight: 600;
}

.video {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  background-color: #000;
}
.video iframe {
  width: 90%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 1020px) {
  .about {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 50px 0px;
    height: auto;
    gap: 50px;
  }
  .about .aboutText {
    width: 100%;
    border: none;
  }
  .video iframe {
    width: 100%;
  }
}
@media screen and (max-width: 678px) {
  .about {
    grid-template-columns: repeat(1, 1fr);
    gap: 0px;
  }
  .video {
    height: 30vh;
  }
}
  `, "",{"version":3,"sources":["webpack://./src/components/About/About.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,YAAY;EACZ,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,UAAU;EACV,aAAa;EACb,kBAAkB;EAClB,6BAA6B;AAC/B;AACA;EACE,kBAAkB;AACpB;AACA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,sBAAsB;AACxB;AACA;EACE,UAAU;EACV,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE;IACE,aAAa;IACb,qCAAqC;IACrC,iBAAiB;IACjB,YAAY;IACZ,SAAS;EACX;EACA;IACE,WAAW;IACX,YAAY;EACd;EACA;IACE,WAAW;EACb;AACF;AACA;EACE;IACE,qCAAqC;IACrC,QAAQ;EACV;EACA;IACE,YAAY;EACd;AACF","sourcesContent":[".about {\n  display: flex;\n  background: black;\n  color: white;\n  height: 200px;\n  align-items: center;\n}\n.about .aboutText {\n  width: 25%;\n  height: 100px;\n  text-align: center;\n  border-right: 1px solid white;\n}\n.about .aboutText:last-child {\n  border-right: none;\n}\n.about .aboutText h1 {\n  color: var(--color);\n  font-weight: 600;\n}\n\n.video {\n  width: 100%;\n  height: 80vh;\n  display: flex;\n  justify-content: center;\n  background-color: #000;\n}\n.video iframe {\n  width: 90%;\n  height: 100%;\n  object-fit: cover;\n}\n\n@media screen and (max-width: 1020px) {\n  .about {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    padding: 50px 0px;\n    height: auto;\n    gap: 50px;\n  }\n  .about .aboutText {\n    width: 100%;\n    border: none;\n  }\n  .video iframe {\n    width: 100%;\n  }\n}\n@media screen and (max-width: 678px) {\n  .about {\n    grid-template-columns: repeat(1, 1fr);\n    gap: 0px;\n  }\n  .video {\n    height: 30vh;\n  }\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
