// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modalTitle  {
    padding: 20px 50px ;
}
 

.modalBody {
    padding: 0px 50px 50px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.modalBody label {
    font-weight: 600;
    font-size: 20px;
}
.modalBody input {
    padding: 15px 10px;
    border: none;
    background-color: #F1F1F1;
    border-radius: 10px;
}
.modalBody button {
    padding: 15px 10px;
    margin-top: 20px;
    border: 2px solid transparent;
}

@media screen and (max-width:600px) {
    .modalTitle {
        padding: 10px;
    }
    .modalBody {
        padding: 0px 20px 30px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/Modal.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;;AAGA;IACI,sBAAsB;IACtB,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;AACA;IACI,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;AACvB;AACA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,6BAA6B;AACjC;;AAEA;IACI;QACI,aAAa;IACjB;IACA;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":[".modalTitle  {\n    padding: 20px 50px ;\n}\n \n\n.modalBody {\n    padding: 0px 50px 50px;\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n}\n.modalBody label {\n    font-weight: 600;\n    font-size: 20px;\n}\n.modalBody input {\n    padding: 15px 10px;\n    border: none;\n    background-color: #F1F1F1;\n    border-radius: 10px;\n}\n.modalBody button {\n    padding: 15px 10px;\n    margin-top: 20px;\n    border: 2px solid transparent;\n}\n\n@media screen and (max-width:600px) {\n    .modalTitle {\n        padding: 10px;\n    }\n    .modalBody {\n        padding: 0px 20px 30px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
