// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pay {
  padding: 100px 0px;
  text-align: center;
}
.pay .payCards {
  display: flex;
  padding: 50px 0px;
  justify-content: space-between;
  width: 80%;
  margin: 0px auto;
}
.payCards .payCard {
  width: 30%;
  height: 200px;
  border: 1px solid var(--color);
  border-radius: 20px;
  overflow: hidden;
}
.pay img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.pay img:first-child{
  transform: scale(0.8);
}
.pay .payCard3 {
  display: flex;
  align-items: center;
}
.pay .payCard3 img{
  width: 50%;
}
@media screen and (max-width: 1024px) {
  .pay .payCards {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 20px 0px;
  }
  .payCards .payCard {
    width: 50%;
  }
  .pay {
    padding: 100px 0px 20px;
    text-align: center;
  }
}
@media screen and (max-width: 650px) {
  .payCards .payCard {
    width: 80%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Pay/Pay.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,iBAAiB;EACjB,8BAA8B;EAC9B,UAAU;EACV,gBAAgB;AAClB;AACA;EACE,UAAU;EACV,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB;AACA;EACE,qBAAqB;AACvB;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,UAAU;AACZ;AACA;EACE;IACE,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;IACT,iBAAiB;EACnB;EACA;IACE,UAAU;EACZ;EACA;IACE,uBAAuB;IACvB,kBAAkB;EACpB;AACF;AACA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":[".pay {\n  padding: 100px 0px;\n  text-align: center;\n}\n.pay .payCards {\n  display: flex;\n  padding: 50px 0px;\n  justify-content: space-between;\n  width: 80%;\n  margin: 0px auto;\n}\n.payCards .payCard {\n  width: 30%;\n  height: 200px;\n  border: 1px solid var(--color);\n  border-radius: 20px;\n  overflow: hidden;\n}\n.pay img{\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n}\n.pay img:first-child{\n  transform: scale(0.8);\n}\n.pay .payCard3 {\n  display: flex;\n  align-items: center;\n}\n.pay .payCard3 img{\n  width: 50%;\n}\n@media screen and (max-width: 1024px) {\n  .pay .payCards {\n    flex-direction: column;\n    align-items: center;\n    gap: 10px;\n    padding: 20px 0px;\n  }\n  .payCards .payCard {\n    width: 50%;\n  }\n  .pay {\n    padding: 100px 0px 20px;\n    text-align: center;\n  }\n}\n@media screen and (max-width: 650px) {\n  .payCards .payCard {\n    width: 80%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
